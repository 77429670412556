import { useState } from "react";

export default function Example() {
  return (
    <div className="bg-red-600">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto animate-bounce"
                src="/crmstudio_logo.svg"
                alt=""
              />
            </a>
          </div>
        </nav>
      </header>
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-20 2xl:py-24 sm:py-16 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 l xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl flex justify-center  sm:justify-normal lg:col-span-2 xl:col-auto">
              <img className="h-20 md:h-24 w-auto" src="/crmstudio_logo.svg" alt="" />
            </h1>
            <div className="mt-2 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg font-semibold leading-8 text-gray-600 text-center sm:text-left xl:text-center">
                No Code Platform
              </p>
              <p className="text-2xl md:text-4xl text-black font-semibold text-center sm:text-start sm:mt-4 xl:hidden">
                Coming Soon<span className="text-red-500 font-bold sm:text-black">!</span>
              </p>
              <p className="text-2xl md:text-4xl text-white font-semibold text-center md:text-end mt-4 absolute top-0 right-10 hidden xl:block">
                Coming Soon<span className="text-white font-bold">!</span>
              </p>
            </div>
            <img
              src="/customer-relationship-management-concept.jpg"
              alt=""
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      <div className="absolute bottom-0 xl:relative mb-10 xl:pt-10 text-center w-full px-4 md:px-0 bg-white">
        <p className="text-center text-sm leading-5 text-black">
          <span className="text-red-500 font-bold">CRMStudio.</span> All rights
          reserved.
        </p>
        <p className="text-center text-sm leading-5">
          <span className="text-red-500 font-bold"> CRMStudio</span> is a
          composable product powered by Microsoft Technologies.
        </p>
      </div>
    </div>
  );
}
